import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/components/Login";
import Enroll from "@/components/Enroll";
import Survey from "@/components/Survey";
import store from "@/store/store";
import NotFound from "@/components/NotFound";
import Register from "@/components/Register";
import PhoneVerification from "@/components/verifications/PhoneVerification";
import EmailVerification from "@/components/verifications/EmailVerification";
import Organization from "@/components/Organization";
import Supervisors from "@/components/Supervisors"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/enroll",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/enroll",
    name: "enroll",
    component: Enroll,
    meta: { requiresAuth: false },
  },
  {
    path: "/survey/:surveyUuid",
    name: "survey",
    component: Survey,
    meta: { requiresAuth: false },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: { requiresAuth: false },
  },
  {
    path: "/validate/phone-number/:phoneNumberVerificationUuid",
    name: "PhoneVerification",
    component: PhoneVerification,
    meta: { requiresAuth: false },
  },
  {
    path: "/validate/email/:emailVerificationUuid",
    name: "EmailVerification",
    component: EmailVerification,
    meta: { requiresAuth: false },
  },
  {
    path: "/organization",
    name: "Organization",
    component: Organization,
    meta: { requiresAuth: true },
  },
  {
    path: "/supervisors",
    name: "Supervisors",
    component: Supervisors,
    meta: { requiresAuth: true }
  },
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isSignedIn = store.getters.loginState;

  // If current route is login, and we're logged in, go to organization
  if (isSignedIn && to.name === "login")
    return router.push({ name: "Organization" });

  // Protected routes
  if (!isSignedIn && to.matched.some((route) => route.meta.requiresAuth)) {
    if (from.name === "login") return { name: "login" };

    return router.push({ name: "login" });
  }

  next();
});

export default router;
