<template>
  <div class="parent">
    <navbar />

    <toolbar title="Welcome to Excess Telecom" />

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-lg-6">
          <div class="row">
            <div class="col-12">
              <h5 class="base-blue">How are we doing?</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p>
                We are commited to providing you with the best infield
                experience possible. So we welcome your comments. Please fill
                out this survey. Thank you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-5">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-lg-6">
          <!-- Loader -->
          <div v-if="isLoading" class="text-center my-2">
            <b-spinner class="mr-3" small></b-spinner>
            <strong>Loading...</strong>
          </div>

          <!-- Error -->
          <div v-if="!isLoading && error" class="text-center my-2">
            <h5>{{ error }}</h5>
          </div>

          <!-- Actual survey -->
          <div v-if="!isLoading && !error && !isSubmitted">
            <div class="container">
              <survey-question
                v-for="(question, index) in questions"
                :key="index"
                :question="questions[index]"
              ></survey-question>
            </div>
            <div class="w-100 mt-4">
              <button
                @click="onSubmit"
                class="btn button1 btn-sm"
                :disabled="isSubmittedLoading"
              >
                {{ isSubmittedLoading ? "Loading..." : "Submit" }}
              </button>
            </div>
          </div>

          <!-- Success -->
          <div v-if="isSubmitted">
            <div class="container">
              <div class="row text-center py-4">
                <div class="text-center w-100">
                  <h5>Survey submitted successfully.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from "@/components/common/Toolbar";
import Navbar from "@/components/common/Navbar";
import SurveyQuestion from "@/components/SurveyQuestion";
export default {
  name: "Survey",
  components: { Navbar, Toolbar, SurveyQuestion },

  data() {
    return {
      isLoading: true,
      isSubmittedLoading: false,
      error: null,
      isSubmitted: false,
      questions: [],
      checkedValue: null,
    };
  },

  computed: {
    isAnswered() {
      return (
        this.questions.every((question) => question.value !== null) &&
        this.questions.length > 0
      );
    },
  },

  mounted() {
    this.validateSurvey(this.$route.params.surveyUuid);
  },

  methods: {
    validateSurvey(surveyUuid) {
      this.isLoading = true;
      this.error = null;

      this.axios
        .get(`surveys/${surveyUuid}`)
        .then(({ data }) => {
          this.questions = data.questions;
        })
        .catch((error) => {
          let code = error.request.status;

          if (code === 404) {
            this.error = `It looks like this survey doesn't exist`;
          } else if (code === 409) {
            this.error = `Sorry! This survey has been submitted already`;
          } else {
            this.handleHttpError(error);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    onSubmit() {
      // Make sure question is answered
      if (!this.isAnswered) {
        this.showError("Please answer the questions before submitting");
        return;
      }

      let surveyUuid = this.$route.params.surveyUuid;
      let body = {
        questions: this.questions,
      };

      this.isSubmittedLoading = true;

      this.axios
        .post(`surveys/${surveyUuid}`, body)
        .then(() => {
          this.isSubmitted = true;
          this.showSuccess("Success!", "Survey sent successfully!");
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.isSubmittedLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.parent {
  width: 100%;
  height: 100%;
  background: #fff;
}

.container {
  max-width: 800px !important;
}

h5.base-blue {
  color: #0c3a99;
  font-weight: 600;
}
</style>
