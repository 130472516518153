<template>
  <div class="title">
    <h3 class="mt-4 pt-1" v-if="title">{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: "Toolbar",

  props: ["title"],

  methods: {
    logout() {
      this.$store.dispatch("logout");

      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped lang="scss"></style>
