import axios from "axios";
import store from "../store/store";

export default function init() {
  axios.interceptors.request.use(
    (config) => {
      const token = store.getters.jwtState;
      if (token) config.headers["x-access-token"] = token;
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
}
