<template>
  <div class="banner alert alert-info" role="alert"> 
    <p> The deadline for updating the Payout Distribution Percentages for the Payroll of {{first_month}} {{from_date}} to {{last_month}} {{to_date}}, is Tuesday {{deadline_month}} {{deadline}} at 11:00 PM Pacific Time</p>
  </div>
  
</template>

<script>
  export default {
    name: "Banner",
    data() {

      return {
        from_date: '---',
        first_month: '---',
        to_date: '---',
        last_month: '---',
        deadline: '---',
        deadline_month: '---'

      }
    },
    mounted() {
      let date = new Date()
      let today = date.getDay()
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

      if(today == 0){
        let days_less = 1000 * 60 * 60 * 24 * 7
        let less = date.getTime() - days_less

        let days_adding = 1000 * 60 * 60 * 24 * 1
        let add = date.getTime() - days_adding

        let new_date = new Date(add)
        let old_date = new Date(less)

        let days_adding2 = 1000 * 60 * 60 * 24 * 3
        let add2 = new_date.getTime() + days_adding2


        let deadline = new Date(add2)

        this.fromdate = old_date.getDate()
        let last_day = new_date.getDate()
        let deadline_day = deadline.getDate()
        
        console.log(first_day)
        console.log(last_day)
        console.log(deadline_day)
      }
      else if( today == 1){
        let days_less = 1000 * 60 * 60 * 24 * 8
        let less = date.getTime() - days_less

        let days_adding = 1000 * 60 * 60 * 24 * 2
        let add = date.getTime() - days_adding

        let new_date = new Date(add)
        let old_date = new Date(less)

        let days_adding2 = 1000 * 60 * 60 * 24 * 3
        let add2 = new_date.getTime() + days_adding2

        let deadline = new Date(add2)

        let first_day = old_date.getDate()
        let last_day = new_date.getDate()
        let deadline_day = deadline.getDate()
        
        let first_month = old_date.getMonth();
        let last_month = new_date.getMonth();
        let deadline_month = deadline.getMonth();
  
        this.first_month = months[first_month]
        this.last_month = months[last_month]
        this.deadline_month = months[deadline_month]
        this.from_date = first_day
        this.to_date = last_day
        this.deadline =  deadline_day
      }
      else if( today == 2){
        let days_less = 1000 * 60 * 60 * 24 * 9
        let less = date.getTime() - days_less

        let days_adding = 1000 * 60 * 60 * 24 * 3
        let add = date.getTime() - days_adding

        let new_date = new Date(add)
        let old_date = new Date(less)

        let days_adding2 = 1000 * 60 * 60 * 24 * 3
        let add2 = new_date.getTime() + days_adding2

        let deadline = new Date(add2)

        let first_day = old_date.getDate()
        let last_day = new_date.getDate()
        let deadline_day = deadline.getDate()
  
        let first_month = old_date.getMonth();
        let last_month = new_date.getMonth();
        let deadline_month = deadline.getMonth();
  
        this.first_month = months[first_month]
        this.last_month = months[last_month]
        this.deadline_month = months[deadline_month]
        this.from_date = first_day
        this.to_date = last_day
        this.deadline =  deadline_day
      }
      else if( today == 3){
        let days_less = 1000 * 60 * 60 * 24 * 10
        let less = date.getTime() - days_less

        let days_adding = 1000 * 60 * 60 * 24 * 4
        let add = date.getTime() - days_adding

        let new_date = new Date(add)
        let old_date = new Date(less)

        let days_adding2 = 1000 * 60 * 60 * 24 * 3
        let add2 = new_date.getTime() + days_adding2


        let deadline = new Date(add2)

        let first_day = old_date.getDate()
        let last_day = new_date.getDate()
        let deadline_day = deadline.getDate()
        
        let first_month = old_date.getMonth();
        let last_month = new_date.getMonth();
        let deadline_month = deadline.getMonth();
  
        this.first_month = months[first_month]
        this.last_month = months[last_month]
        this.deadline_month = months[deadline_month]
        this.from_date = first_day
        this.to_date = last_day
        this.deadline =  deadline_day
      }
      else if( today == 4){
        let days_less = 1000 * 60 * 60 * 24 * 11
        let less = date.getTime() - days_less

        let days_adding = 1000 * 60 * 60 * 24 * 5
        let add = date.getTime() - days_adding

        let new_date = new Date(add)
        let old_date = new Date(less)

        let days_adding2 = 1000 * 60 * 60 * 24 * 3
        let add2 = new_date.getTime() + days_adding2


        let deadline = new Date(add2)

        let first_day = old_date.getDate()
        let last_day = new_date.getDate()
        let deadline_day = deadline.getDate()
        
        let first_month = old_date.getMonth();
        let last_month = new_date.getMonth();
        let deadline_month = deadline.getMonth();
  
        this.first_month = months[first_month]
        this.last_month = months[last_month]
        this.deadline_month = months[deadline_month]
        this.from_date = first_day
        this.to_date = last_day
        this.deadline =  deadline_day
      }
      else if( today == 5){
        let days_less = 1000 * 60 * 60 * 24 * 12
        let less = date.getTime() - days_less

        let days_adding = 1000 * 60 * 60 * 24 * 6
        let add = date.getTime() - days_adding

        let new_date = new Date(add)
        let old_date = new Date(less)

        let days_adding2 = 1000 * 60 * 60 * 24 * 3
        let add2 = new_date.getTime() + days_adding2


        let deadline = new Date(add2)

        let first_day = old_date.getDate()
        let last_day = new_date.getDate()
        let deadline_day = deadline.getDate()
        
        let first_month = old_date.getMonth();
        let last_month = new_date.getMonth();
        let deadline_month = deadline.getMonth();
  
        this.first_month = months[first_month]
        this.last_month = months[last_month]
        this.deadline_month = months[deadline_month]
        this.from_date = first_day
        this.to_date = last_day
        this.deadline =  deadline_day
      }
      else if( today == 6){
        let days_less = 1000 * 60 * 60 * 24 * 13
        let less = date.getTime() - days_less

        let days_adding = 1000 * 60 * 60 * 24 * 7
        let add = date.getTime() - days_adding

        let new_date = new Date(add)
        let old_date = new Date(less)

        let days_adding2 = 1000 * 60 * 60 * 24 * 3
        let add2 = new_date.getTime() + days_adding2


        let deadline = new Date(add2)

        let first_day = old_date.getDate()
        let last_day = new_date.getDate()
        let deadline_day = deadline.getDate()
        
        let first_month = old_date.getMonth();
        let last_month = new_date.getMonth();
        let deadline_month = deadline.getMonth();
  
        this.first_month = months[first_month]
        this.last_month = months[last_month]
        this.deadline_month = months[deadline_month]
        this.from_date = first_day
        this.to_date = last_day
        this.deadline =  deadline_day
      }
    }

  };
</script>

<style scoped lang="scss"></style>
