<template>
  <div class="parent">
    <navbar />

    <toolbar title="Email verification" />

    <div class="container mt-4">
      <div class="col-12 text-center">
        <div class="text-center my-2" v-if="isLoading">
          <b-spinner class="mr-3" small />
          <strong>Verifying...</strong>
        </div>

        <div class="success" v-if="!isLoading && isSuccess">
          <img src="@/assets/img/done_black_24dp.svg" />
          <h4 class="font-weight-normal mt-4">Success</h4>
          <p class="mt-4">{{ successMessage }}</p>
          <p class="mt-5">
            <a href="/">Go to home</a>
          </p>
        </div>

        <div class="failure" v-if="!isLoading && !isSuccess">
          <h4 class="font-weight-normal mt-5">Error</h4>
          <p class="mt-4">There has been an error validating your email</p>
          <p class="mt-5">
            <a href="/">Go to home</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from "@/components/common/Toolbar";
import Navbar from "@/components/common/Navbar";
export default {
  name: "EmailVerification",
  components: { Navbar, Toolbar },

  data() {
    return {
      isLoading: true,
      isSuccess: true,
      successMessage: "",
    };
  },

  created() {
    this.verifyEmail(this.$route.params.emailVerificationUuid);
  },

  methods: {
    verifyEmail(emailVerificationUuid) {
      this.isLoading = true;

      this.axios
        .post(`verification/email`, { emailVerificationUuid })
        .then(({ data }) => {
          this.successMessage = data.msg;
          this.isSuccess = true;
        })
        .catch((error) => {
          console.error(error);
          this.successMessage = "";
          this.isSuccess = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.parent {
  width: 100%;
  height: 100%;
  background: #fff;
}

.success {
  img {
    width: 60px;
    filter: invert(59%) sepia(62%) saturate(384%) hue-rotate(101deg)
      brightness(93%) contrast(90%);
  }
}
</style>
