<template>
  <div class="parent">
    <navbar />

    <toolbar title="Not found" />

    <div class="container mt-5">
      <div class="row">
        <div class="col-12 text-center">
          <h5 class="font-weight-normal">
            It looks like this page doesn't exist!
          </h5>
          <br />
          <br />
          <br />
          <a href="/">Go to home</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from "@/components/common/Toolbar";
import Navbar from "@/components/common/Navbar";
export default {
  name: "NotFound",
  components: { Navbar, Toolbar },
};
</script>

<style scoped lang="scss">
.parent {
  width: 100%;
  height: 100%;
  background: #fff;
}
</style>
