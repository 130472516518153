<template>
  <div class="parent">
    <navbar />

    <toolbar title="Enrollment" />

    <div class="container">
      <div class="row text-center">
        <div class="align-self-center col-12">
          <div
            class="form mx-auto text-start mb-5"
            :class="{ 'd-none': step !== 1 }"
          >
            <b-form @submit.prevent="onSubmit" novalidate class="text-left">
              <b-form-group label="Agent ID" label-for="agentId" class="mt-4">
                <b-form-input
                  id="agentId"
                  name="agentId"
                  v-model="$v.form.agent_id.$model"
                  :state="validateState('agent_id')"
                />
                <b-form-invalid-feedback
                  >Please enter your agent ID</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                label="Enrollment ID"
                label-for="enrollmentId"
                class="mt-4"
              >
                <b-form-input
                  id="enrollmentId"
                  name="enrollmentId"
                  v-model="$v.form.enrollment_id.$model"
                  :state="validateState('enrollment_id')"
                />
                <b-form-invalid-feedback
                  >Please enter the enrollment ID</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-checkbox
                id="serviceAwareness"
                class="mt-4"
                v-model="checkboxes[0]"
                name="serviceAwareness"
                value="EPSA-01"
                unchecked-value=""
                >Excess Product/Service Awareness</b-form-checkbox
              >

              <b-form-checkbox
                id="appFeatureAwareness"
                class="mt-4"
                v-model="checkboxes[1]"
                name="appFeatureAwareness"
                value="EAFA-01"
                unchecked-value=""
                >Excess App Feature Awareness</b-form-checkbox
              >

              <b-form-checkbox
                id="tabletFeatures"
                class="mt-4"
                v-model="checkboxes[2]"
                name="tabletFeatures"
                value="TSAF-01"
                unchecked-value=""
                >Tablet Statifiation and Features</b-form-checkbox
              >

              <b-form-checkbox
                id="excessContactInfo"
                class="mt-4"
                v-model="checkboxes[3]"
                name="excessContactInfo"
                value="ECSH-01"
                unchecked-value=""
                >Excess Contact Info: Customer Service Hours</b-form-checkbox
              >

              <button
                type="submit"
                class="btn w-100 mt-5 button1 btn-sm"
                :disabled="isLoading"
              >
                {{ isLoading ? "Loading..." : "Submit" }}
              </button>
            </b-form>
          </div>

          <div class="form mx-auto" :class="{ 'd-none': step !== 2 }">
            <h4 class="font-weight-normal mt-4">Success</h4>
            <p class="mt-4">
              Customer enrolled successfully. Please send an email or phone
              number verification.
            </p>

            <div class="text-left">
              <b-form-group
                label="Customer email"
                label-for="customerEmail"
                class="mt-4"
              >
                <b-form-input
                  id="customerEmail"
                  name="customerEmail"
                  v-model="customerEmail"
                  :disabled="true"
                />
              </b-form-group>
            </div>

            <div class="text-left">
              <b-form-group
                label="Customer phone number"
                label-for="customerPhone"
                class="mt-4"
              >
                <b-form-input
                  id="customerPhone"
                  name="customerPhone"
                  v-model="customerPhone"
                  :disabled="true"
                />
              </b-form-group>
            </div>

            <button
              class="btn w-100 mt-3 button1 btn-sm"
              :disabled="isLoading || isEmailSent || customerEmail == null"
              @click.prevent="sendEmailVerification"
            >
              Send verification email
            </button>

            <button
              class="btn w-100 mt-4 button1 btn-sm"
              :disabled="isLoading || isSMSSent || customerPhone == null"
              @click.prevent="sendPhoneVerification"
            >
              Send verification SMS
            </button>

            <a
              class="btn w-100 mt-4 button1 btn-sm"
              :disabled="isLoading"
              href="https://si.tc/excessapp"
              target="_blank"
            >
              Download Excess App
            </a>

            <a
              class="btn w-100 mt-4 button1 btn-sm"
              :disabled="isLoading"
              href="https://si.tc/apptutorial"
              target="_blank"
            >
              How to install the app without a Play store account
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from "@/components/common/Toolbar";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Navbar from "@/components/common/Navbar";

export default {
  name: "Enroll",
  components: { Navbar, Toolbar },
  mixins: [validationMixin],

  data() {
    return {
      step: 1,
      enrollmentId: null,

      form: {
        agent_id: null,
        enrollment_id: null,
      },

      checkboxes: Array(4).fill(null),

      customerEmail: null,
      customerPhone: null,

      isLoading: false,
      isEmailSent: false,
      isSMSSent: false,
    };
  },

  validations: {
    form: {
      agent_id: { required },
      enrollment_id: { required },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.isLoading = true;

      let body = {
        enrollmentId: this.form.enrollment_id,
        agentId: this.form.agent_id,
        checkboxes: this.checkboxes.filter((c) => c),
      };

      this.axios
        .post("enrollments", body)
        .then((res) => {
          this.step = 2;

          this.enrollmentId = res.data.data.id;
          this.customerEmail = res.data.data.email;
          this.customerPhone = res.data.data.phoneNumber;
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.isLoading = false;
        });
    },

    sendEmailVerification() {
      this.isLoading = true;

      this.axios
        .post(`enrollments/${this.enrollmentId}/send-verification-email`)
        .then(() => {
          this.isEmailSent = true;
          this.showSuccess("Success", "Verification email sent successfully");
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.isLoading = false;
        });
    },

    sendPhoneVerification() {
      this.isLoading = true;

      this.axios
        .post(`enrollments/${this.enrollmentId}/send-verification-sms`)
        .then(() => {
          this.isSMSSent = true;
          this.showSuccess("Success", "Verification SMS sent successfully");
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.parent {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.form {
  max-width: 500px;
  padding: 20px;
}

.container {
  margin-top: -0.75rem;
}
</style>
