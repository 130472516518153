import Vue from "vue";
import Vuex from "vuex";
import router from "@/router/router";

Vue.use(Vuex);

let jsonWebToken = require("jsonwebtoken");

export default new Vuex.Store({
  state: {
    isSignedIn: false,
    signedInJwt: null,
    user: null
  },

  getters: {
    loginState: (state) => state.isSignedIn,
    jwtState: (state) => state.signedInJwt,

    userRole: state => {
      return state.user?.role ?? {}
    },

    userPermissions: (state) => {
      return state.user?.role?.permissions ?? []
    }
  },

  mutations: {
    login(state, payload) {
      state.isSignedIn = true;
      state.signedInJwt = payload.jwt;
    },

    logout(state) {
      state.isSignedIn = false;
      state.signedInJwt = null;
    },

    setUser(state, payload) {
      state.user = payload;
    }
  },

  actions: {
    // To log-in with an already validated JWT
    login({ commit, dispatch }, jwt) {
      const jwt_decoded = jsonWebToken.decode(jwt, { complete: true });

      const now = new Date();
      const expMs = jwt_decoded.payload.exp * 1000;
      const expiresInMsg = expMs - now.getTime();

      // Set expiration timeout to log out automatically
      setTimeout(() => {
        dispatch("logout");
      }, expiresInMsg);

      localStorage.setItem("session", jwt);

      commit("login", {
        jwt: jwt,
        decoded: jwt_decoded,
      });

      Vue.axios.get("/user").then(({data}) => {
        const user = data.data;
        commit("setUser", user)
      })

      if (router.currentRoute.name === "login")
        router.push({ name: "Organization" });
    },

    logout({ commit }) {
      localStorage.removeItem("session");

      commit("logout");
    },
  },

  modules: {},
});
