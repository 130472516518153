<template>
  <div class="parent">
    <!-- Supervisor Modal -->
    <b-modal id="supervisorModal" title="Supervisor">
      <div class="modal-body">
        <form>
          <b-form-group
            :label="supervisorLabel"
            label-for="supervisor"
            class="mt-2"
          >
            <!-- select box with all supervisors -->
            <b-form-select id="supervisor" v-model="supervisorModal.supervisor">
              <option :value="null">No supervisor</option>
              <option
                v-for="supervisor in supervisorModal.supervisors"
                :value="supervisor.id"
                :key="supervisor.id"
              >
                {{ supervisor.firstName }} {{ supervisor.lastName }}
              </option>
            </b-form-select>
          </b-form-group>
        </form>
      </div>
      <template #modal-footer="{ cancel }">
        <div class="w-100 d-flex justify-content-between">
          <div>
            <b-button variant="secondary" @click="cancel()">Cancel</b-button>
          </div>
          <div>
            <b-button variant="primary" @click="setSupervisor">Update</b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <!-- Modal to create/edit override -->
    <b-modal id="overrideModal" title="Payout Distribution">
      <div>
        <form @submit.prevent="onOverrideSubmit" novalidate>
          <b-form-group
            label="Agent percentage (%)"
            label-for="agentPercentage"
            class="mt-2"
          >
            <b-form-input
              type="number"
              id="agentPercentage"
              name="agentPercentage"
              v-model="overrideModal.agentPercentage.value"
            />
            <b-form-invalid-feedback
              :state="overrideModal.agentPercentage.error == null"
            >
              {{ overrideModal.agentPercentage.error }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Supervisor percentage (%)"
            label-for="supervisorPercentage"
            class="mt-4"
          >
            <b-form-input
              type="number"
              id="supervisorPercentage"
              name="supervisorPercentage"
              v-model="overrideModal.supervisorPercentage.value"
            />
            <b-form-invalid-feedback
              :state="overrideModal.supervisorPercentage.error == null"
            >
              {{ overrideModal.supervisorPercentage.error }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Retailer percentage (%)"
            label-for="retailerPercentage"
            class="mt-4 mb-4"
          >
            <b-form-input
              type="number"
              id="retailerPercentage"
              name="retailerPercentage"
              v-model="retailerPercentage"
              disabled
            />
            <b-form-invalid-feedback
              :state="overrideModal.retailerPercentageError == null"
            >
              {{ overrideModal.retailerPercentageError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button type="submit" class="d-none" />
        </form>
      </div>

      <template #modal-footer="{ cancel }">
        <div class="w-100 d-flex justify-content-between">
          <div>
            <b-button variant="secondary" @click="cancel()">Cancel</b-button>
          </div>
          <div>
            <b-button
              variant="danger"
              v-b-modal.overrideRemoveModal
              class="mr-2"
              v-if="overrideModal.overrideId !== null"
            >
              Remove
            </b-button>
            <b-button variant="primary" @click="onOverrideSubmit">
              {{ overrideModal.isCreating ? "Create" : "Update" }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <!-- Confirmation modal to remove override -->
    <b-modal id="overrideRemoveModal" title="Confirmation">
      <div>
        Are you sure you want to remove this payout distribution configuration?
      </div>

      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" class="mr-2" @click="cancel"
          >No, cancel</b-button
        >
        <b-button variant="danger" @click="onOverrideRemoveSubmit"
          >Yes, remove</b-button
        >
      </template>
    </b-modal>

    <!-- Navbar -->
    <navbar />

    <!-- Banner for deadline -->
    <banner />

    <!-- Toolbar -->
    <toolbar title="Organization" />

    <div class="container mt-5 d-flex justify-content-end">
      <b-form-checkbox
        id="inactive-agents"
        v-model="showInactiveAgents"
        name="inactive-agents"
      >
        Show Inactive Agents
      </b-form-checkbox>
    </div>

    <!-- Main container -->
    <div class="container mt-5">
      <div class="row text-left">
        <div class="col-12 mb-5">
          <!-- Loader -->
          <div v-if="isMastersLoading" class="text-center my-2">
            <b-spinner class="mr-3" small></b-spinner>
            <strong>Loading...</strong>
          </div>

          <!-- Main content -->
          <div v-if="!isMastersLoading">
            <!-- If nothing was found -->
            <div v-if="masters.length === 0" class="text-center">
              {{ noMastersFoundMessage }}
            </div>

            <!-- To show masters -->
            <div v-if="masters.length > 0" class="accordion">
              <!-- Card for each Master -->
              <b-card
                v-for="(master, master_i) in masters"
                no-body
                class="mb-2 master-card"
                :key="master.id"
              >
                <!-- Card header, showing master name -->
                <b-card-header header-tag="header" class="p-1">
                  <a href="#" @click.prevent="toggleMaster($event, master_i)">
                    <div class="name">
                      {{ canSeeMasters ? master.name : "" }}
                    </div>

                    <!-- <div class="supervisor">
                      <b-button
                        type="button"
                        @click="openMasterSupervisorModal(master.id, master_i)"
                        variant="primary"
                      >
                        Supervisor
                      </b-button>
                    </div> -->

                    <div class="override">
                      <b-button
                        v-if="canSeeMasters"
                        type="button"
                        @click="openMasterOverride(master, master_i)"
                        class="ml-3 mr-3"
                        variant="primary"
                        :disabled="disableMasterOverride"
                      >
                        Payout Distribution
                      </b-button>
                    </div>

                    <div class="right">
                      <b-spinner v-show="master.isLoading" small />
                      <BIconChevronRight v-show="!master.isExpanded" />
                      <BIconChevronDown v-show="master.isExpanded" />
                    </div>
                  </a>
                </b-card-header>

                <!-- Card content, showing retailers accordion -->
                <b-collapse
                  accordion="masters-accordion"
                  v-model="master.isExpanded"
                >
                  <b-card-body>
                    <!-- If no retailers were found -->
                    <div
                      v-if="master.retailers.length === 0"
                      class="text-center"
                    >
                      No retailers found
                    </div>

                    <div v-if="master.retailers.length > 0">
                      <!-- Card for each Retailer -->
                      <b-card
                        v-for="(retailer, retailer_i) in master.retailers"
                        no-body
                        class="mb-2 retailer-card"
                        :key="retailer.id"
                      >
                        <!-- Card header, showing retailer name -->
                        <b-card-header>
                          <a
                            href="#"
                            @click.prevent="
                              toggleRetailer($event, master_i, retailer_i)
                            "
                          >
                            <div class="name">
                              {{ retailer.name }}
                            </div>

                            <div class="mr-3 text-right">
                              <b-form
                                @submit.prevent="
                                  updateWeeklySalesGoal(retailer)
                                "
                                inline
                              >
                                <label
                                  v-if="canSeeWeeklySalesGoal"
                                  class="mr-sm-2"
                                  >Weekly sales goal</label
                                >
                                <b-form-input
                                  v-if="canSeeWeeklySalesGoal"
                                  type="text"
                                  size="sm"
                                  v-model="retailer.weeklySalesGoal"
                                  :disabled="retailer.isLoading"
                                />
                                <b-button
                                  v-if="canSeeWeeklySalesGoal"
                                  @click="updateWeeklySalesGoal(retailer)"
                                  type="submit"
                                  variant="primary"
                                  class="ml-1"
                                  :disabled="retailer.isLoading"
                                >
                                  Update
                                </b-button>
                              </b-form>
                              <!-- <b-button
                                @click="
                                  openRetailerSupervisorModal(
                                    retailer.id,
                                    retailer_i
                                  )
                                "
                                type="button"
                                variant="primary"
                                class="mt-2 mr-3"
                              >
                                Supervisor
                              </b-button> -->
                              <b-button
                                @click="
                                  openRetailerOverride(
                                    retailer,
                                    master_i,
                                    retailer_i
                                  )
                                "
                                type="button"
                                variant="primary"
                                class="mt-2"
                              >
                                Payout Distribution
                              </b-button>
                            </div>

                            <span class="right">
                              <b-spinner v-show="retailer.isLoading" small />
                              <BIconChevronRight
                                v-show="!retailer.isExpanded"
                              />
                              <BIconChevronDown v-show="retailer.isExpanded" />
                            </span>
                          </a>
                        </b-card-header>

                        <!-- Card body, showing agents list -->
                        <b-collapse
                          accordion="retailers-accordion"
                          v-model="retailer.isExpanded"
                        >
                          <b-card-body>
                            <template v-if="showInactiveAgents">
                              <div
                                v-if="retailer.agents.length === 0"
                                class="text-center"
                              >
                                No agents found
                              </div>

                              <ul v-else class="agents">
                                <li
                                  v-for="(agent, agent_i) in retailer.agents"
                                  :key="agent.id"
                                >
                                  <div class="name">
                                    {{ agent.lastName }}, {{ agent.firstName }}
                                    {{ agent.middleName }}
                                    <br />
                                    <small>{{ agent.loginId }}</small>
                                    <br />
                                    <small>{{
                                      agent.isActive ? "Active" : "Inactive"
                                    }}</small>
                                  </div>

                                  <div class="override-btn">
                                    <b-button
                                      @click="
                                        openAgentSupervisorModal(
                                          agent.id,
                                          agent_i
                                        )
                                      "
                                      type="button"
                                      variant="primary"
                                      class="mr-3"
                                    >
                                      Supervisor
                                    </b-button>
                                    <b-button
                                      @click="
                                        openAgentOverride(
                                          agent,
                                          master_i,
                                          retailer_i,
                                          agent_i
                                        )
                                      "
                                      type="button"
                                      variant="primary"
                                    >
                                      Payout Distribution
                                    </b-button>
                                  </div>
                                </li>
                              </ul>
                            </template>
                            <template v-else>
                              <div
                                v-if="
                                  retailer.agents.filter((a) => a.isActive)
                                    .length === 0
                                "
                                class="text-center"
                              >
                                No agents found
                              </div>

                              <ul v-else class="agents">
                                <li
                                  v-for="(
                                    agent, agent_i
                                  ) in retailer.agents.filter(
                                    (a) => a.isActive
                                  )"
                                  :key="agent.id"
                                >
                                  <div class="name">
                                    {{ agent.lastName }}, {{ agent.firstName }}
                                    {{ agent.middleName }}
                                    <br />
                                    <small>{{ agent.loginId }}</small>
                                    <br />
                                    <small>{{
                                      agent.isActive ? "Active" : "Inactive"
                                    }}</small>
                                  </div>

                                  <div class="override-btn">
                                    <b-button
                                      @click="
                                        openAgentSupervisorModal(
                                          agent.id,
                                          agent_i
                                        )
                                      "
                                      type="button"
                                      variant="primary"
                                      class="mr-3"
                                    >
                                      Supervisor
                                    </b-button>
                                    <b-button
                                      @click="
                                        openAgentOverride(
                                          agent,
                                          master_i,
                                          retailer_i,
                                          agent_i
                                        )
                                      "
                                      type="button"
                                      variant="primary"
                                    >
                                      Payout Distribution
                                    </b-button>
                                  </div>
                                </li>
                              </ul>
                            </template>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from "@/components/common/Toolbar";
import { BIconChevronRight, BIconChevronDown } from "bootstrap-vue";
import Navbar from "@/components/common/Navbar";
import Banner from "@/components/common/Banner";

export default {
  name: "Organization",
  components: {
    Navbar,
    Toolbar,
    Banner,
    BIconChevronRight,
    BIconChevronDown,
  },

  /**
   * Data
   */
  data() {
    return {
      isMastersLoading: true,
      isSupervisorsLoading: false,
      noMastersFoundMessage: "",
      masters: [],
      supervisors: [],

      // When creating/editing a master/retailer/agent
      overrideModal: {
        type: null, // Type of edit -- master/retailer/agent
        master_i: null, // Master index
        retailer_i: null, // Retailer index (if it applies)
        agent_i: null, // Agent index (if it applies)
        overrideId: null, // Null if we're creating, otherwise override ID
        agentPercentage: {
          error: null,
          value: null,
        },
        supervisorPercentage: {
          error: null,
          value: null,
        },
        retailerPercentage: null,
        retailerPercentageError: null,
      },

      supervisorModal: {
        supervisors: [],
        supervisor: null,
        agent_i: null,
        master_i: null,
        retailer_i: null,
        agentId: null,
        masterId: null,
        retailerId: null,
      },

      accordions: {
        masterIndex: null,
        retailerIndex: null,
      },

      showInactiveAgents: true,
    };
  },

  /**
   * Computed properties
   */
  computed: {
    // Whenever agentPercentage or supervisorPercentage changes, we'll calculate retailerPercentage
    // agentPercentage + supervisorPercentage must be between 0 and 100
    // Also, decimal places are limited to 2
    // We'll use this computed property to our benefit, and we'll be validating both agentPercentage
    // and supervisorPercentage
    retailerPercentage: {
      get() {
        let agentPercent = this.overrideModal.agentPercentage.value;
        let supervisorPercent = this.overrideModal.supervisorPercentage.value;
        let agentPercentFloat = parseFloat(agentPercent);
        let supervisorPercentFloat = parseFloat(supervisorPercent);
        let agentPercentInt = Math.floor(agentPercentFloat);
        let supervisorPercentInt = Math.floor(supervisorPercentFloat);

        let isError = false;

        if (agentPercent == null) {
          this.overrideModal.agentPercentage.error = null;
        } else if (
          isNaN(agentPercentFloat) ||
          agentPercentFloat < 0 ||
          agentPercentFloat > 100
        ) {
          this.overrideModal.agentPercentage.error = `Agent percentage must be between 0 and 100`;
          isError = true;
        } else if (
          agentPercentFloat - agentPercentInt > 0 &&
          agentPercentFloat.toString().split(".")[1].length > 2
        ) {
          this.overrideModal.agentPercentage.error = `Agent percentage is restricted to 2 decimal places`;
          isError = true;
        } else {
          this.overrideModal.agentPercentage.error = null;
        }

        if (supervisorPercent == null) {
          this.overrideModal.supervisorPercentage.error = null;
        } else if (
          isNaN(supervisorPercentFloat) ||
          supervisorPercentFloat < 0 ||
          supervisorPercentFloat > 100
        ) {
          this.overrideModal.supervisorPercentage.error = `Supervisor percentage must be between 0 and 100`;
          isError = true;
        } else if (
          supervisorPercentFloat - supervisorPercentInt > 0 &&
          supervisorPercentFloat.toString().split(".")[1].length > 2
        ) {
          this.overrideModal.supervisorPercentage.error = `Supervisor percentage is restricted to 2 decimal places`;
          isError = true;
        } else {
          this.overrideModal.supervisorPercentage.error = null;
        }

        if (isError || agentPercent == null || supervisorPercent == null)
          return 100;

        if (agentPercentFloat + supervisorPercentFloat > 100) {
          this.overrideModal.retailerPercentageError = `Agent percent + supervisor percent must not exceed 100%`;
          return 100;
        } else {
          this.overrideModal.retailerPercentageError = null;
        }

        return (100 - agentPercentFloat - supervisorPercentFloat).toFixed(2);
      },

      set(val) {
        this.retailerPercentage = val;
      },
    },

    userRole() {
      return this.$store.getters.userRole;
    },

    userPermissions() {
      return this.$store.getters.userPermissions;
    },

    /**
     * Used for filtering cases for Retailer role. e.g. Retailer user roles
     * should not see Master info (Apparently this is a compliance issue).
     * TODO -- This needs to be reworked when we have more time.
     */
    canSeeMasters() {
      return this.userRole.name === "Admin" || this.userRole.name === "Master";
    },

    disableMasterOverride() {
      return this.userPermissions.every((p) => {
        return p.code != "MASTERS-EDIT-01" && p.code != "MASTERS-EDIT-OWN-01";
      });
    },
    /**
     * Only Admin and Master user roles
     * should see and update weekly goal form.
     */
    canSeeWeeklySalesGoal() {
      return this.userRole.name === "Admin" || this.userRole.name === "Master";
    },

    //used to know if user is retailer. Supervisor modal label changes if so.
    supervisorLabel() {
      return this.userRole.name === "Retailer"
        ? "Select a Supervisor"
        : "Supervisor must share retailer with agent";
    },
  },
  /**
   * Initializer
   */
  created() {
    this.getMasters();
    this.getSupervisors();
  },

  /**
   * Methods
   */
  methods: {
    /**
     * When user clicks on a master, we'll load retailers and expand the list
     */
    toggleMaster(e, i) {
      /**
       * Retailers should be expanded all the time if retailer user role
       * anyway so this function shouldn't execute on those cases.
       */
      if (!this.canSeeMasters) return;
      let classes = Array.from(e.target.classList);
      let shouldCancel = classes.find(
        (el) => el === "form-control" || el === "btn"
      );
      let master = this.masters[i];

      if (shouldCancel) return;

      // If it is expanded, just collapse.
      // Otherwise, we need to load its contents before trying to expand (only if we're not loading already)
      if (master.isExpanded) master.isExpanded = false;
      else if (!master.isLoading) this.getRetailers(i);

      // Needed for global tracking of open accordions
      this.accordions.retailerIndex = null;
      this.accordions.masterIndex = i;
    },

    /**
     * When user clicks on a retailer, we'll load agents and expand the list
     */
    toggleRetailer(e, master_i, retailer_i) {
      let classes = Array.from(e.target.classList);
      let shouldCancel = classes.find(
        (el) => el === "form-control" || el === "btn"
      );
      let retailer = this.masters[master_i].retailers[retailer_i];

      if (shouldCancel) return;

      if (retailer.isExpanded)
        this.setRetailerProp(master_i, retailer_i, { isExpanded: false });
      else if (!retailer.isLoading) this.getAgents(master_i, retailer_i);

      // Needed for global tracking of open accordions
      this.accordions.retailerIndex = retailer_i;
    },

    /**
     * To get list of masters
     */
    getMasters() {
      this.isMastersLoading = true;

      this.axios
        .get("masters")
        .then((res) => {
          this.masters = res.data.data;
          this.masters.forEach((el) => {
            el.isExpanded = false;
            el.isLoading = false;
            el.retailers = [];
          });

          if (this.masters.length === 0) {
            this.noMastersFoundMessage = "No masters found";
          } else if (!this.canSeeMasters && this.userRole.id) {
            this.getRetailers(0);
          }
        })
        .catch((err) => {
          this.handleHttpError(err);
          const msg =
            err?.response?.data?.msg ?? "An unexpected error occurred";
          this.noMastersFoundMessage = msg;
        })
        .finally(() => {
          this.isMastersLoading = false;
        });
    },

    /**
     * To get list of retailers (for a given master)
     */
    getRetailers(master_i) {
      let master = this.masters[master_i];

      // We can't do this.masters[master_i].isLoading = true due to VueJS' caveats
      this.$set(this.masters, master_i, { ...master, isLoading: true });

      this.axios
        .get(`retailers`, { params: { masterId: master.id } })
        .then((res) => {
          // Load data and expand
          master.retailers = res.data.data;
          master.retailers.forEach((el) => {
            el.isExpanded = false;
            el.isLoading = false;
            el.agents = [];
          });
          master.isExpanded = true;
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.$set(this.masters, master_i, { ...master, isLoading: false });
        });
    },

    /**
     * To get list of agents (for a given master and retailer)
     */
    getAgents(master_i, retailer_i) {
      let master = this.masters[master_i];
      let retailer = master.retailers[retailer_i];

      this.setRetailerProp(master_i, retailer_i, { isLoading: true });

      this.axios
        .get(`agents`, { params: { retailerId: retailer.id } })
        .then((res) => {
          this.setRetailerProp(master_i, retailer_i, {
            agents: res.data.data,
            isExpanded: true,
          });
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.setRetailerProp(master_i, retailer_i, { isLoading: false });
        });
    },

    /**
     * To get list of supervisors
     **/
    getSupervisors() {
      this.isSupervisorsLoading = true;

      this.axios
        .get("supervisors")
        .then((res) => {
          this.supervisors = res.data.data;
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.isSupervisorsLoading = false;
        });
    },

    /**
     * Sometimes Vue stops reacting to changes in complex (nested) arrays. To solve this
     * issue, we need to use this.$set, assigning a new (independent) copy of the object
     * that we're trying to update.
     * So here we create a copy of the given retailer object, we update the property
     * we want to update, and then we set the object back.
     * https://v2.vuejs.org/v2/guide/reactivity.html
     */
    setRetailerProp(master_i, retailer_i, newProps) {
      let retailerCopy = Object.assign(
        {},
        this.masters[master_i].retailers[retailer_i],
        newProps
      );
      this.$set(this.masters[master_i].retailers, retailer_i, retailerCopy);
    },

    /**
     * Set supervisor
     */
    setSupervisor() {
      if (this.supervisorModal.agentId !== null) {
        this.axios
          .patch(`agents/${this.supervisorModal.agentId}/set-supervisor`, {
            supervisorId: this.supervisorModal.supervisor ?? 0,
          })
          .then((res) => {
            this.supervisorModal.supervisor = res.data.data.supervisorId;
            this.getAgents(
              this.accordions.masterIndex ?? 0,
              this.accordions.retailerIndex ?? 0
            );
            this.showSuccess("Success", "Supervisor set successfully");
            this.$bvModal.hide("supervisorModal");
          })
          .catch(this.handleHttpError);
      } else if (this.supervisorModal.retailerId !== null) {
        this.axios
          .patch(
            `retailers/${this.supervisorModal.retailerId}/set-supervisor`,
            {
              supervisorId: this.supervisorModal.supervisor ?? 0,
            }
          )
          .then((res) => {
            this.supervisorModal.supervisor = res.data.data.supervisorId;
            this.showSuccess("Success", "Supervisor set successfully");
            this.$bvModal.hide("supervisorModal");
            this.getRetailers(
              this.supervisorModal.master_i == null
                ? 0
                : this.supervisorModal.master_i
            );
          })
          .catch(this.handleHttpError);
      } else if (this.supervisorModal.masterId !== null) {
        this.axios
          .patch(`masters/${this.supervisorModal.masterId}/set-supervisor`, {
            supervisorId: this.supervisorModal.supervisor ?? 0,
          })
          .then((res) => {
            this.supervisorModal.supervisor = res.data.data.supervisorId;
            this.showSuccess("Success", "Supervisor set successfully");
            this.$bvModal.hide("supervisorModal");
            this.getMasters();
          })
          .catch(this.handleHttpError);
      } else {
        this.showError("No master, retailer or agent selected");
      }
    },

    /**
     * To update weeklySalesGoal of a retailer
     */
    updateWeeklySalesGoal(retailer) {
      let weeklySalesGoal = retailer.weeklySalesGoal;
      if (!/^\d+$/.test(weeklySalesGoal) || weeklySalesGoal <= 0) {
        this.showError(`Weekly sales goal must be an integer greater than 0`);
        return;
      }

      retailer.isLoading = true;

      this.axios
        .patch(`retailers/${retailer.id}/weekly-sales-goal`, {
          weekly_sales_goal: parseInt(weeklySalesGoal),
        })
        .then(() => {
          this.showSuccess(`Success`, `Weekly sales goal updated successfully`);
        })
        .catch(this.handleHttpError)
        .finally(() => {
          retailer.isLoading = false;
        });
    },

    /**
     * To open an agent override modal
     */
    openAgentOverride(agent, master_i, retailer_i, agent_i) {
      this.overrideModal.type = "agent";

      // Load details to our data object
      this.overrideModal.master_i = master_i;
      this.overrideModal.retailer_i = retailer_i;
      this.overrideModal.agent_i = agent_i;

      if (agent.override !== null) {
        this.overrideModal.overrideId = agent.override.id;
        this.overrideModal.isCreating = false;
        this.overrideModal.agentPercentage.value = (
          +agent.override.agentPercentage * 100
        ).toFixed(2);
        this.overrideModal.supervisorPercentage.value = (
          +agent.override.supervisorPercentage * 100
        ).toFixed(2);
      } else {
        this.overrideModal.overrideId = null;
        this.overrideModal.isCreating = true;
        this.overrideModal.agentPercentage.value = null;
        this.overrideModal.supervisorPercentage.value = null;
      }

      this.$bvModal.show("overrideModal");
    },

    /**
     * To open a retailer override modal
     */
    openRetailerOverride(retailer, master_i, retailer_i) {
      this.overrideModal.type = "retailer";

      // Load details to our data object
      this.overrideModal.master_i = master_i;
      this.overrideModal.retailer_i = retailer_i;
      this.overrideModal.agent_i = null;

      if (retailer.override !== null) {
        this.overrideModal.overrideId = retailer.override.id;
        this.overrideModal.isCreating = false;
        this.overrideModal.agentPercentage.value = (
          +retailer.override.agentPercentage * 100
        ).toFixed(2);
        this.overrideModal.supervisorPercentage.value = (
          +retailer.override.supervisorPercentage * 100
        ).toFixed(2);
      } else {
        this.overrideModal.overrideId = null;
        this.overrideModal.isCreating = true;
        this.overrideModal.agentPercentage.value = null;
        this.overrideModal.supervisorPercentage.value = null;
      }

      this.$bvModal.show("overrideModal");
    },

    /**
     * To open a master override modal
     */
    openMasterOverride(master, master_i) {
      this.overrideModal.type = "master";

      // Load details to our data object
      this.overrideModal.master_i = master_i;
      this.overrideModal.retailer_i = null;
      this.overrideModal.agent_i = null;

      if (master.override !== null) {
        this.overrideModal.overrideId = master.override.id;
        this.overrideModal.isCreating = false;
        this.overrideModal.agentPercentage.value = (
          +master.override.agentPercentage * 100
        ).toFixed(2);
        this.overrideModal.supervisorPercentage.value = (
          +master.override.supervisorPercentage * 100
        ).toFixed(2);
      } else {
        this.overrideModal.overrideId = null;
        this.overrideModal.isCreating = true;
        this.overrideModal.agentPercentage.value = null;
        this.overrideModal.supervisorPercentage.value = null;
      }

      this.$bvModal.show("overrideModal");
    },

    /**
     * Opens a supervisor modal for masters
     */
    openMasterSupervisorModal(masterId, masterIndex) {
      this.supervisorModal.masterId = masterId;
      this.supervisorModal.master_i = masterIndex;
      this.supervisorModal.retailer_i = null;
      this.supervisorModal.agent_i = null;

      this.masters.forEach((master) => {
        if (master.id === masterId) {
          this.supervisorModal.supervisor = master.supervisorId;
        }
      });

      this.$bvModal.show("supervisorModal");
    },

    /**
     * Opens a supervisor modal for retailers
     */
    openRetailerSupervisorModal(retailerId, retailerIndex) {
      this.supervisorModal.retailerId = retailerId;
      this.supervisorModal.master_i = null;
      this.supervisorModal.retailer_i = retailerIndex;
      this.supervisorModal.agent_i = null;

      this.masters.forEach((master) => {
        master.retailers.forEach((retailer) => {
          if (retailer.id === retailerId) {
            this.supervisorModal.supervisor = retailer.supervisorId;
          }
        });
      });

      this.$bvModal.show("supervisorModal");
    },

    /**
     * Opens a supervisor modal for agents
     */
    openAgentSupervisorModal(agentId, agentIndex) {
      this.supervisorModal.agentId = agentId;
      this.supervisorModal.master_i = null;
      this.supervisorModal.retailer_i = null;
      this.supervisorModal.agent_i = agentIndex;

      this.masters.forEach((master) => {
        master.retailers.forEach((retailer) => {
          retailer.agents.forEach((agent) => {
            if (agent.id === agentId) {
              this.supervisorModal.supervisor = agent.supervisorId;
              this.supervisorModal.supervisors = this.supervisors.filter(
                (supervisor) => {
                  return supervisor.retailerId == retailer.id;
                }
              );
            }
          });
        });
      });

      this.$bvModal.show("supervisorModal");
    },

    /**
     * Called when submitting override (either for creating or editing)
     */
    onOverrideSubmit() {
      // If we have any errors, cancel
      if (
        this.overrideModal.agentPercentage.error !== null ||
        this.overrideModal.supervisorPercentage.error !== null ||
        this.overrideModal.retailerPercentageError !== null
      )
        return;

      // overrideId == null if we're creating, otherwise it'll be equal
      // to the override ID we're updating
      let isCreating = this.overrideModal.overrideId === null;

      if (isCreating) this.createOverride();
      else this.updateOverride();
    },

    /**
     * To create a new override
     */
    createOverride() {
      let master_i = this.overrideModal.master_i;
      let retailer_i = this.overrideModal.retailer_i;
      let agent_i = this.overrideModal.agent_i;

      let body = {
        agentPercentage: (
          this.overrideModal.agentPercentage.value / 100
        ).toFixed(4),
        supervisorPercentage: (
          this.overrideModal.supervisorPercentage.value / 100
        ).toFixed(4),
      };

      if (this.overrideModal.type === "master")
        body.masterId = this.masters[master_i].id;
      else if (this.overrideModal.type === "retailer")
        body.retailerId = this.masters[master_i].retailers[retailer_i].id;
      else if (this.overrideModal.type === "agent")
        body.agentId =
          this.masters[master_i].retailers[retailer_i].agents[agent_i].id;

      this.axios
        .post(`/overrides`, body)
        .then(() => {
          // Retrieve data again, to keep everything up to date
          this.updateAfterOverrideChange();

          this.showSuccess("Success", `Payout Distribution set successfully`);

          this.$bvModal.hide("overrideModal");
        })
        .catch(this.handleHttpError);
    },

    /**
     * To update an override
     */
    updateOverride() {
      let body = {
        agentPercentage: (
          this.overrideModal.agentPercentage.value / 100
        ).toFixed(4),
        supervisorPercentage: (
          this.overrideModal.supervisorPercentage.value / 100
        ).toFixed(4),
      };

      this.axios
        .put(`/overrides/${this.overrideModal.overrideId}`, body)
        .then(() => {
          // Retrieve data again, to keep everything up to date
          this.updateAfterOverrideChange();

          this.showSuccess("Success", `Payout Distribution set successfully`);

          this.$bvModal.hide("overrideModal");
        })
        .catch(this.handleHttpError);
    },

    /**
     * To delete an override (after user confirmed action)
     */
    onOverrideRemoveSubmit() {
      this.axios
        .delete(`/overrides/${this.overrideModal.overrideId}`)
        .then(() => {
          // Retrieve data again, to keep everything up to date
          this.updateAfterOverrideChange();

          this.showSuccess(`Success`, `Payout Distribution set successfully`);

          this.$bvModal.hide("overrideRemoveModal");
          this.$bvModal.hide("overrideModal");
        })
        .catch(this.handleHttpError);
    },

    /**
     * To reload corresponding list after user performed ANY
     * action to override (created, updated, deleted)
     */
    updateAfterOverrideChange() {
      let master_i = this.overrideModal.master_i;
      let retailer_i = this.overrideModal.retailer_i;

      if (this.overrideModal.type === "master") this.getMasters();
      else if (this.overrideModal.type === "retailer")
        this.getRetailers(master_i);
      else if (this.overrideModal.type === "agent")
        this.getAgents(master_i, retailer_i);
    },
  },
};
</script>

<style scoped lang="scss">
.parent {
  width: 100%;
  height: 100%;
  background: #fff;
}

.container {
  max-width: 800px !important;
}

.accordion > .card:not(:last-of-type),
.accordion > .card:not(:first-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem !important;
}

.master-card {
  .card-header {
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 15px;
      text-decoration: none;

      .name {
        flex-grow: 1;
      }

      .right {
        display: flex;
        align-items: center;

        .spinner-border {
          margin-right: 10px;
        }
        svg {
          margin-top: -1px;
        }
      }
    }
  }
}

.retailer-card {
  .card-header {
    a {
      padding: 0;

      .name {
        flex-grow: 1;
      }

      input {
        max-width: 120px;
      }
    }
  }
}

ul.agents {
  list-style-type: none;
  padding: 0;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    margin-bottom: 6px;
    padding: 0.75rem;
    border-radius: 5px;
    color: #555;
  }
}

button {
  padding: 0.4rem 0.8rem !important;
  font-size: 0.85rem !important;
}
</style>
