<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand to="/enroll">
        <img src="@/assets/img/excess-logo-white.svg" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" />

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/enroll" :active="this.$route.name === 'enroll'"
            >Enroll</b-nav-item
          >
          <b-nav-item
            to="/organization"
            v-if="isSignedIn"
            :active="this.$route.name === 'Organization'"
            >Organization</b-nav-item
          >
          <b-nav-item
            to="/supervisors"
            v-if="isSignedIn && canSeeSupervisors"
            :active="this.$route.name === 'Supervisors'"
            >Supervisors</b-nav-item
          >
        </b-navbar-nav>

        <!-- Right items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/login" v-if="!isSignedIn">Login</b-nav-item>
          <b-nav-item @click.prevent="logout" v-if="isSignedIn"
            >Logout</b-nav-item
          >
          <b-nav-item v-if="!isSignedIn" to="/register">Register</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",

  computed: {
    isSignedIn() {
      return this.$store.getters.loginState;
    },
    canSeeSupervisors() {
      const permissions = this.$store.getters.userPermissions;
      return permissions.some((p) => {
        return (
          p.code == "SUPERVISORS-INDEX-01" ||
          p.code == "SUPERVISORS-INDEX-OWN-01"
        );
      });
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");

      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped lang="scss">
.navbar {
  background-color: #0c3a99 !important;

  a.navbar-brand {
    margin-right: 2rem;

    img {
      height: 34px;
    }
  }

  li.nav-item:not(:last-child) {
    margin-right: 10px;
  }

  a.nav-link {
    color: rgba(255, 255, 255, 0.65);

    &:hover:not(.active) {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

@media (max-width: 991px) {
  li.nav-item {
    margin-right: 0 !important;
  }
}
</style>
