<template>
  <div class="parent">
    <navbar />

    <toolbar title="Register" />

    <div class="container">
      <div class="row text-center">
        <div class="align-self-center col-12">
          <div class="form mx-auto text-start mb-5">
            <b-form @submit.prevent="onSubmit" novalidate class="text-left">
              <b-form-group
                label="First name *"
                label-for="firstName"
                class="mt-4"
              >
                <b-form-input
                  id="firstName"
                  name="firstName"
                  v-model="$v.form.firstName.$model"
                  :state="validateState('firstName')"
                />
                <b-form-invalid-feedback
                  >Please enter your first name</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                label="Middle name"
                label-for="middleName"
                class="mt-4"
              >
                <b-form-input
                  id="middleName"
                  name="middleName"
                  v-model="$v.form.middleName.$model"
                  :state="validateState('middleName')"
                />
                <b-form-invalid-feedback
                  >Please enter your middle name</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                label="Last name *"
                label-for="lastName"
                class="mt-4"
              >
                <b-form-input
                  id="lastName"
                  name="lastName"
                  v-model="$v.form.lastName.$model"
                  :state="validateState('lastName')"
                />
                <b-form-invalid-feedback
                  >Please enter your last name</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group label="Email *" label-for="email" class="mt-4">
                <b-form-input
                  id="email"
                  name="email"
                  v-model="$v.form.email.$model"
                  :state="validateState('email')"
                />
                <b-form-invalid-feedback
                  >Please enter a valid email address</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                label="Password *"
                label-for="password"
                class="mt-4"
              >
                <b-form-input
                  id="password"
                  name="password"
                  type="password"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                />
                <b-form-invalid-feedback
                  >Please enter a password. It must be at least 5 characters
                  long</b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                label="Password confirmation *"
                label-for="passwordConfirmation"
                class="mt-4"
              >
                <b-form-input
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type="password"
                  v-model="$v.form.passwordConfirmation.$model"
                  :state="validateState('passwordConfirmation')"
                />
                <b-form-invalid-feedback
                  >Password and password confirmation must
                  match</b-form-invalid-feedback
                >
              </b-form-group>

              <button
                type="submit"
                class="btn w-100 mt-4 button1 btn-sm"
                :disabled="isLoading"
              >
                {{ isLoading ? "Loading..." : "Submit" }}
              </button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from "@/components/common/Toolbar";
import { validationMixin } from "vuelidate";
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";
import Navbar from "@/components/common/Navbar";

export default {
  name: "Register",
  components: { Navbar, Toolbar },
  mixins: [validationMixin],

  data() {
    return {
      form: {
        firstName: null,
        middleName: null,
        lastName: null,
        email: null,
        password: null,
        passwordConfirmation: null,
      },

      isLoading: false,
    };
  },

  validations: {
    form: {
      firstName: { required },
      middleName: {},
      lastName: { required },
      email: { required, email },
      password: { required, minLength: minLength(5) },
      passwordConfirmation: { required, sameAsPassword: sameAs("password") },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.isLoading = true;

      this.axios
        .post(`register`, this.form)
        .then(async () => {
          this.showSuccess("Registered", "Registered successfully!");
          await new Promise((res) => setTimeout(res, 3000));
          this.$router.push({ name: "login" });
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.parent {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.form {
  max-width: 500px;
  padding: 20px;
}

.container {
  margin-top: -0.75rem;
}
</style>
