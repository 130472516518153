<template>
  <div class="parent">
    <!-- Modal to create/edit supervisor -->
    <b-modal id="supervisorModal" title="Supervisor Information">
      <div>
        <form @submit.prevent="onSupervisorSubmit" novalidate>
          <b-form-group label="First Name" label-for="firstName" class="mt-2">
            <b-form-input
              type="text"
              id="firstName"
              name="firstName"
              v-model="$v.supervisorModal.firstName.value.$model"
              :state="validationStatus('firstName')"
            />
            <b-form-invalid-feedback>
              First Name Required
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Last Name" label-for="lastName" class="mt-4">
            <b-form-input
              type="text"
              id="lastName"
              name="lastName"
              v-model="$v.supervisorModal.lastName.value.$model"
              :state="validationStatus('lastName')"
            />
            <b-form-invalid-feedback>
              Last Name Required
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Email" label-for="email" class="mt-4 mb-4">
            <b-form-input
              type="email"
              id="email"
              name="email"
              v-model="supervisorModal.email.value"
            />
          </b-form-group>

          <b-button type="submit" class="d-none" />

          <b-form-group
            v-if="supervisorModal.isCreating"
            label="Select retailer"
            label-for="Retailer"
            class="mt-2"
          >
            <!-- select box with all retailers -->
            <b-form-select
              id="Retailer"
              v-model="$v.supervisorModal.retailerId.$model"
              :state="validationStatus('retailerId')"
            >
              <option disabled>-- Please select an option --</option>
              <option
                v-for="retailer in retailers"
                :value="retailer.id"
                :key="retailer.id"
              >
                {{ retailer.name }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback>
              Retailer Required
            </b-form-invalid-feedback>
          </b-form-group>
        </form>
      </div>
      <template #modal-footer="{ cancel }">
        <div class="w-100 d-flex justify-content-between">
          <div>
            <b-button
              variant="secondary"
              @click="
                cancel();
                $v.$reset();
              "
              :disabled="loadingFlagDisableButton"
              >Cancel</b-button
            >
          </div>
          <div>
            <b-button
              variant="primary"
              @click="onSupervisorSubmit"
              :disabled="loadingFlagDisableButton"
            >
              {{ supervisorModal.isCreating ? "Create" : "Update" }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Confirmation modal to remove supervisor -->
    <b-modal id="supervisorRemoveModal" title="Confirmation">
      <div>Are you sure you want to remove this supervisor?</div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="secondary"
          class="mr-2"
          @click="cancel"
          :disabled="loadingFlagDisableButton"
          >No, cancel</b-button
        >
        <b-button
          variant="danger"
          @click="onSupervisorRemoveSubmit"
          :disabled="loadingFlagDisableButton"
          >Yes, remove</b-button
        >
      </template>
    </b-modal>

    <!-- Navbar -->
    <navbar />

    <!-- Toolbar -->
    <toolbar title="Supervisors" />

    <!-- Main container -->
    <div class="container mt-3">
      <div class="row text-left">
        <div class="col-12 mb-5">
          <!-- Loader -->
          <div v-if="isSupervisorsLoading" class="text-center my-2">
            <b-spinner class="mr-3" small></b-spinner>
            <strong>Loading...</strong>
          </div>

          <!-- Main content -->
          <div v-if="!isSupervisorsLoading">
            <!-- If nothing was found -->
            <div v-if="supervisors.length === 0" class="text-center">
              {{ noSupervisorsFoundMessage }}
            </div>

            <!-- Create Supervisor Button -->
            <div class="text-right mb-4">
              <b-button
                @click="
                  openSupervisorModal(supervisor);
                  supervisorModal.isCreating = true;
                "
                type="button"
                variant="primary"
                class="ml-5 mr-5"
              >
                Create Supervisor
              </b-button>
            </div>

            <!-- To show supervisors -->
            <div v-if="supervisors.length > 0" class="accordion">
              <!-- Card for each Supervisor -->
              <b-card
                v-for="(supervisor, supervisorIndex) in supervisors"
                no-body
                class="mb-2 supervisor-card"
                :key="supervisor.id"
              >
                <!-- Card header, showing supervisor name -->
                <b-card-header header-tag="header" class="p-1">
                  <a
                    href="#"
                    @click.prevent="toggleSupervisor($event, supervisorIndex)"
                  >
                    <div class="name">
                      {{
                        supervisor.lastName
                          ? `${supervisor.lastName}, ${supervisor.firstName}`
                          : supervisor.firstName
                      }}
                    </div>
                    <div class="deleteSupervisor">
                      <b-button
                        type="button"
                        class="ml-3"
                        v-b-modal.supervisorRemoveModal
                        @click="supervisorId = supervisor.id"
                        variant="secondary"
                      >
                        Delete
                      </b-button>
                    </div>
                    <div class="editSupervisor">
                      <b-button
                        @click="openSupervisorModal(supervisor)"
                        type="button"
                        variant="primary"
                        class="ml-3 mr-3"
                      >
                        Edit
                      </b-button>
                    </div>
                    <div class="right">
                      <BIconChevronRight v-show="!supervisor.isExpanded" />
                      <BIconChevronDown v-show="supervisor.isExpanded" />
                    </div>
                  </a>
                </b-card-header>
                <b-collapse
                  accordion="supervisors-accordion"
                  v-model="supervisor.isExpanded"
                >
                  <b-card-body>
                    <!-- If no retailers were found -->
                    <div class="text-left">
                      Retailer:
                      {{
                        supervisor.retailer && supervisor.retailer.name
                          ? supervisor.retailer.name
                          : "N/A"
                      }}
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toolbar from "@/components/common/Toolbar";
import { BIconChevronRight, BIconChevronDown } from "bootstrap-vue";
import Navbar from "@/components/common/Navbar";

import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "Supervisors",
  components: {
    Navbar,
    Toolbar,
    BIconChevronRight,
    BIconChevronDown,
  },
  data() {
    return {
      isSupervisorsLoading: true,
      noSupervisorsFoundMessage: "",
      noRetailersFoundMessage: "",
      supervisors: [],
      supervisorId: "",
      retailers: [],
      supervisor: null,
      loadingFlagDisableButton: false,

      // When creating/editing a supervisor
      supervisorModal: {
        supervisorId: null, // Null if we're creating, otherwise override ID
        firstName: {
          error: null,
          value: "",
        },
        lastName: {
          error: null,
          value: "",
        },
        email: {
          error: null,
          value: "",
        },
        retailerId: null,
        isCreating: false,
      },
    };
  },

  validations: {
    supervisorModal: {
      firstName: {
        value: { required },
      },
      lastName: {
        value: { required },
      },
      retailerId: {
        required: requiredIf(function () {
          return this.supervisorModal.isCreating; //conditional validator for agent dropdown, only validate when creating new supervisor
        }),
      },
    },
  },
  /**
   * Initializer
   */
  created() {
    this.getSupervisors();
    this.getRetailers();
  },

  methods: {
    validationStatus(validation) {
      const { $dirty, $error } = this.$v.supervisorModal[validation];
      return $dirty ? !$error : null;
    },

    //get all available retailers
    getRetailers() {
      this.axios
        .get(`retailers`)
        .then((res) => {
          this.retailers = res.data.data;
          this.retailers.forEach((el) => {
            el.isExpanded = false;
            el.isLoading = false;
          });

          if (this.retailers.length === 0)
            this.noRetailersFoundMessage = "No retailers found";
        })
        .catch(this.handleHttpError);
    },

    /**
     * To get list of supervisors
     **/
    getSupervisors() {
      this.isSupervisorsLoading = true;

      this.axios
        .get("supervisors")
        .then((res) => {
          this.supervisors = res.data.data.map((supervisor) => {
            return { ...supervisor, isExpanded: false };
          });

          if (this.supervisors.length === 0)
            this.noSupervisorsFoundMessage = "No supervisors found";
        })
        .catch((error) => {
          this.handleHttpError(error);
          const message =
            error?.response?.data?.msg ?? "An unexpected error occurred";
          this.noSupervisorsFoundMessage = message;
        })
        .finally(() => {
          this.isSupervisorsLoading = false;
        });
    },

    /**
     * When user clicks on a supervisor, we'll expand the card
     */
    toggleSupervisor(e, i) {
      let classes = Array.from(e.target.classList);
      let shouldCancel = classes.find(
        (el) => el === "form-control" || el === "btn"
      );
      let supervisor = this.supervisors[i];

      if (shouldCancel) return;

      // If it is expanded, just collapse.
      // Otherwise, we need to load its contents before trying to expand (only if we're not loading already)
      supervisor.isExpanded = !supervisor.isExpanded;
    },

    /**
     * To open modal to edit/create
     */
    openSupervisorModal(supervisor = null) {
      if (supervisor) {
        this.supervisorModal = {
          supervisorId: supervisor.id, // Null if we're creating, otherwise override ID
          firstName: {
            error: null,
            value: supervisor.firstName,
          },
          lastName: {
            error: null,
            value: supervisor.lastName,
          },
          email: {
            error: null,
            value: supervisor.email,
          },
        };
      } else {
        this.supervisorModal = {
          supervisorId: null, // Null if we're creating, otherwise override ID
          firstName: {
            error: null,
            value: "",
          },
          lastName: {
            error: null,
            value: "",
          },
          email: {
            error: null,
            value: "",
          },
          retailerId: "",
        };
      }

      this.$bvModal.show("supervisorModal");
    },

    /**
     * When user submits supervisor form (edit or update)
     */
    onSupervisorSubmit() {
      // If we have any errors, cancel

      this.$v.supervisorModal.$touch();
      if (this.$v.supervisorModal.$anyError) {
        return;
      }

      // supervisor == null if we're creating, otherwise it'll be equal
      // to the supervisor ID we're updating
      let isCreating = this.supervisorModal.supervisorId === null;

      if (isCreating) this.createSupervisor();
      else this.updateSupervisor();
      this.$v.$reset();
    },

    /**
     * To create a new supervisor
     */
    createSupervisor() {
      this.loadingFlagDisableButton = true;
      let body = {
        firstName: this.supervisorModal.firstName.value,
        lastName: this.supervisorModal.lastName.value,
        email: this.supervisorModal.email.value,
        retailerId: this.supervisorModal.retailerId,
      };

      this.axios
        .post(`/supervisors`, body)
        .then(() => {
          // Retrieve data again, to keep everything up to date
          this.getSupervisors();
          this.showSuccess("Success", `Supervisor created successfully.`);
          this.$bvModal.hide("supervisorModal");
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.loadingFlagDisableButton = false;
        });
    },

    /**
     * To update an override
     */
    updateSupervisor() {
      this.loadingFlagDisableButton = true;
      let body = {
        firstName: this.supervisorModal.firstName.value,
        lastName: this.supervisorModal.lastName.value,
        email: this.supervisorModal.email.value,
      };

      this.axios
        .put(`/supervisors/${this.supervisorModal.supervisorId}`, body)
        .then(() => {
          // Retrieve data again, to keep everything up to date
          this.getSupervisors();
          this.showSuccess("Success", `Supervisor updated successfully`);
          this.$bvModal.hide("supervisorModal");
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.loadingFlagDisableButton = false;
        });
    },
    /**
     * To delete a supervisor (after user confirmed action)
     */
    onSupervisorRemoveSubmit() {
      this.loadingFlagDisableButton = true;
      this.axios
        .delete(`/supervisors/${this.supervisorId}`)
        .then(() => {
          this.supervisorId = "";

          // Retrieve data again, to keep everything up to date
          this.updateAfterSupervisorChange();
          this.showSuccess(`Success`, `Supervisor deleted successfully`);
          this.$bvModal.hide("supervisorRemoveModal");
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.loadingFlagDisableButton = false;
        });
    },
    //gets refreshed list of supervisors
    updateAfterSupervisorChange() {
      this.getSupervisors();
    },
  },
};
</script>

<style scoped lang="scss">
.parent {
  width: 100%;
  height: 100%;
  background: #fff;
}

.container {
  max-width: 800px !important;
}

.accordion > .card:not(:last-of-type),
.accordion > .card:not(:first-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem !important;
}

.supervisor-card {
  .card-header {
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 15px;
      text-decoration: none;

      .name {
        flex-grow: 1;
      }

      .right {
        display: flex;
        align-items: center;

        .spinner-border {
          margin-right: 10px;
        }
        svg {
          margin-top: -1px;
        }
      }
    }
  }
}

button {
  padding: 0.4rem 0.8rem !important;
  font-size: 0.85rem !important;
}
</style>