import Vue from "vue";

Vue.mixin({
  methods: {
    showError(msg) {
      this.$bvToast.toast(msg, {
        title: "Error",
        autoHideDelay: 3500,
        appendToast: true,
        toaster: "b-toaster-bottom-right",
        variant: "danger",
        solid: true,
      });
    },

    showSuccess(title, msg) {
      this.$bvToast.toast(msg, {
        title: title,
        autoHideDelay: 2000,
        appendToast: true,
        toaster: "b-toaster-bottom-right",
      });
    },
  },
});
