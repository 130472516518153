import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import BootstrapVue from "bootstrap-vue";
import VueAxios from "vue-axios";
import axios from "axios";

// Import mixins
import "@/mixins/toastsMixin";
import "@/mixins/errorHandlerMixin";

Vue.config.productionTip = false;

// Import global styling
import "@/assets/global.css";

// Import Bootstrap and BootstrapVue CSS files
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { Vuelidate } from "vuelidate";

// Configure auth interceptor
import authInterceptor from "./interceptors/auth";
authInterceptor();

// Make Bootstrap available in the project
Vue.use(BootstrapVue);

// We could optionally install BootstrapVue icons plugin
//Vue.use(IconsPlugin)

// Add axios and vue-axios (axios will be accessible using
// Vue.axios)
Vue.use(VueAxios, axios);

// Configure Axios base URL
Vue.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL || "";

// Add Vuelidate
Vue.use(Vuelidate);

// Run logic to get JWT from the local storage
const jsonWebToken = require("jsonwebtoken");

// Check if we're logged in, and if the JWT is still valid
const jwt = localStorage.getItem("session");

if (jwt) {
  let jwt_decoded = jsonWebToken.decode(jwt);

  if (jwt_decoded === null) {
    // Invalid JWT... Store will redirect to login page automatically
    store.dispatch("logout");
  } else {
    // JWT is valid... Check expiration
    const now = new Date();
    const expMs = jwt_decoded.exp * 1000;
    const expiresInMs = expMs - now.getTime();

    // Expired? Logout
    if (expiresInMs < 0) store.dispatch("logout");
    else {
      // Valid... Load information from the JWT into the store, and
      // redirect if needed
      store.dispatch("login", jwt);

      if (
        router.currentRoute.name === "login" &&
        router.currentRoute.name !== "organization"
      )
        router.push({ name: "Organization" });
    }
  }
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
