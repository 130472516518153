<template>
  <div class="row text-center align-items-center py-4 border-bottom">
    <div class="col-12">{{ question.description }}</div>
    <div class="col-12 mt-3">
      <b-form-group class="mb-0">
        <b-form-radio-group
          id="btn-radios-1"
          v-model="question.value"
          :options="options"
          button-variant="outline-primary"
          name="radios-btn-default"
          buttons
        />
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options:
        this.question.type === "1-5"
          ? [1, 2, 3, 4, 5]
          : [
              { value: 0, text: "No" },
              { value: 1, text: "Yes" },
            ],
    };
  },
};
</script>
