<template>
  <div class="container" id="login">
    <div class="row text-center">
      <div class="align-self-center col-12">
        <div class="login-box mx-auto text-start">
          <div class="logo text-center">
            <img src="@/assets/img/excess-logo.svg" alt="Excess logo" />
          </div>

          <b-form @submit.prevent="onSubmit" novalidate class="text-left">
            <b-form-group label="Email" label-for="email" class="mt-4">
              <b-form-input
                id="email"
                name="email"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
              ></b-form-input>
              <b-form-invalid-feedback
                >Please enter your email address</b-form-invalid-feedback
              >
            </b-form-group>

            <div class="form-group">
              <b-form-group label="Password" label-for="password" class="mt-4">
                <b-form-input
                  id="password"
                  name="password"
                  type="password"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                ></b-form-input>
                <b-form-invalid-feedback
                  >Please enter your username</b-form-invalid-feedback
                >
              </b-form-group>
            </div>

            <button
              type="submit"
              class="btn w-100 mt-4 button1 btn-sm"
              :disabled="isLoading"
            >
              {{ isLoading ? "Loading..." : "Login" }}
            </button>
          </b-form>

          <hr />

          <router-link to="/register" class="btn w-100 button1 btn-sm">
            Register
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  data() {
    return {
      isLoading: false,

      form: {
        email: null,
        password: null,
      },
    };
  },

  validations: {
    form: {
      email: { required },
      password: { required },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.isLoading = true;

      this.axios
        .post(`login`, {
          email: this.form.email,
          password: this.form.password,
        })
        .then((res) => {
          let jwt = res.data.data.token;

          // Login! Store will redirect us automatically
          this.$store.dispatch("login", jwt);
        })
        .catch(this.handleHttpError)
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
#app {
  background-color: #f9f9f9;
}

#login {
  &.container {
    height: 100%;

    & > .row {
      min-height: 100%;
    }
  }

  .logo {
    img {
      width: 180px;
      max-width: 100%;
    }
  }

  .login-box {
    max-width: 400px;
    background-color: #fff;
    padding: 40px 30px 30px 30px;
    border-radius: 6px;
    box-shadow: 0 0 10px #e0e0e0;
  }
}
</style>
