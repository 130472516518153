import Vue from "vue";

Vue.mixin({
  methods: {
    handleHttpError(error) {
      console.error(error);
      const message = error?.response?.data?.msg ?? "An unexpected error occurred.";
      this.showError(message);
    }
  }
})